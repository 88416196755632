import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Row, Col } from 'react-bootstrap';
import { logo } from 'assets';
import 'App.css';

const App = () => {
  return (
    <Container fluid className="coming-soon-container d-flex flex-column justify-content-center align-items-center text-center">
      <Row>
        <Col>
          <img src={logo} alt="Blue Squirrel Studios Logo" className="img-fluid logo mb-4" style={{height: "60vh"}} />
        </Col>
      </Row>
      <Row>
        <Col>
          <p className="lead mb-2 mb-5">Stepped Out To Grab A Nut.. Be With You Soon!</p>
          <h1 className="display-4 mb-3">Blue Squirrel Studios</h1>
          <p>Want to get in touch? Write to us at developer@bluesquirrelstudios.com.</p>
        </Col>
      </Row>
    </Container>
  );
};

export default App;